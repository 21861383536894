import React from "react";
import Helmet from "react-helmet";
import Header from "../components/layouts/header";
import css from "@emotion/css";
import { graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { HeroBanner } from "../components/HeroBanner";
import { PrimaryLink } from "../components/PrimaryButton";

function Index({ data }) {
  const {
    contentfulHomePage: {
      pageTitle,
      metaDescription,
      heroBanner: { title, subtitle, buttonText, buttonLink, heroImage },
      subBannerHeadline,
      subBannerParagraph: {
        child: { html: subHtml }
      },
      ctaBanners
    }
  } = data;
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content="Ratio, fintech, finance, software" />
      </Helmet>
      <div>
        <div className="hero bg-gradient">
          {" "}
          <Header />
          <div id="heroBanner" className="container section-py">
            <HeroBanner
              heroImage={heroImage}
              title={title}
              subtitle={subtitle}
              buttonText={buttonText}
              buttonLink={buttonLink}
            />
          </div>
        </div>
      </div>

      <div id="subBanner" className="section-py " css={css``}>
        <div className="container">
          <div className="row">
            <h1
              className="col-md-4 offset-md-1   col-12 bold-headline text-primary"
              css={css`
                font-size: 38px;
                line-height: 1.1;
              `}
            >
              {subBannerHeadline}
            </h1>
            <div
              className="col-md-5 col-12 offset-md-2 mt-3 mt-md-0"
              css={css`
                font-size: 18px;
                line-height: 22px;
              `}
              dangerouslySetInnerHTML={{ __html: subHtml }}
            />
          </div>
        </div>
      </div>

      {ctaBanners.map((cta, i) => {
        return i % 2 ? (
          <CtaMobile
            key={cta.title}
            title={cta.title}
            background={cta.backgroundImage}
            buttonText={cta.buttonText}
            buttonLink={cta.buttonLink}
            subtitle={cta.paragraphText}
            ver={i}
          />
        ) : (
          <CtaFullImage
            key={cta.title}
            title={cta.title}
            background={cta.backgroundImage}
            buttonText={cta.buttonText}
            buttonLink={cta.buttonLink}
            subtitle={cta.paragraphText}
            ver={i}
          />
        );
      })}
    </>
  );
}

export default Index;

export const query = graphql`
  {
    contentfulHomePage {
      id
      pageTitle: title
      metaDescription
      heroBanner {
        title
        subtitle
        buttonText
        buttonLink
        heroImage {
          title
          fluid(maxWidth: 1920, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
      }
      subBannerHeadline
      subBannerParagraph {
        child: childMarkdownRemark {
          html
        }
      }
      ctaBanners {
        title
        paragraphText
        buttonText
        buttonLink
        backgroundImage {
          fluid(maxWidth: 1920, quality: 75) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`;

const CtaFullImage = ({
  background,
  title,
  subtitle,
  buttonText,
  buttonLink,
  ver
}) => {
  return (
    <BackgroundImage
      fluid={background.fluid}
      style={{
        display: "flex"
      }}
    >
      <div className="container  align-self-center">
        <div className="row">
          <div className="col-12 col-md-4 offset-0 offset-md-1  d-flex ">
            <div className="section-py ">
              <div
                className="circle"
                css={css`
                  text-align: center;
                  background: rgba(255, 255, 255, 0.9);
                  h2 {
                    font-size: 1.2rem;
                  }
                  p {
                    font-size: 0.8rem;
                  }
                  a {
                    font-size: 0.8rem;
                  }
                  width: calc(100vw - 30px);
                  height: calc(100vw - 30px);
                  padding-top: 2em;
                  padding-bottom: 2em;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  @media (min-width: 576px) {
                    h2 {
                      font-size: 1.75rem;
                    }
                    p {
                      font-size: 1rem;
                    }
                    a {
                      font-size: initial;
                    }
                    border-radius: 50%;
                    padding-top: 0em;
                    padding-bottom: 0em;
                    width: 540px;
                    height: 540px;
                  }
                  @media (min-width: 768px) {
                    background: #fff;
                    border-radius: 50%;
                    width: 360px;
                    height: 360px;
                  }
                  @media (min-width: 992px) {
                    text-align: left;
                    width: 380px;
                    height: 380px;
                  }
                  @media (min-width: 1200px) {
                    width: 400px;
                    height: 400px;
                  }
                `}
              >
                <div
                  className="content"
                  css={css`
                    max-width: calc(100vw - 120px);
                    @media (min-width: 576px) {
                      max-width: calc(100vw - 180px);
                    }
                    @media (min-width: 768px) {
                      max-width: 280px;
                    }
                    @media (min-width: 992px) {
                    }
                  `}
                >
                  <h2
                    className="bold-headline"
                    css={css`
                      font-size: 28px;
                      color: var(--primary);
                    `}
                  >
                    {title}
                  </h2>
                  <p
                    css={css`
                      font-size: 16px;
                      line-height: 19px;
                    `}
                  >
                    {subtitle}
                  </p>{" "}
                  <PrimaryLink
                    className="btn btn-lg text-white bg-gradient"
                    to={buttonLink}
                    text={buttonText}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

const CtaMobile = ({
  background,
  title,
  subtitle,
  buttonText,
  buttonLink,
  ver
}) => {
  return (
    <div>
      <div
        fluid={background.fluid}
        css={css`
          background-color: #e7eaea;
        `}
        style={{ backgroundPosition: null, backgroundSize: null }}
      >
        <div className="container  align-self-center">
          <div className="row">
            <div
              className="col-md-5 col-lg-6"
              css={css`
                background: url(${background.fluid.src});
                background-position: 50% 100%;
                background-repeat: no-repeat;
                background-size: 311px 400px;
                @media (min-width: 768px) {
                  min-height: 500px;
                }
              `}
            />
            <div className="col-12 col-md-4   d-flex">
              <div className="section-py ">
                <div
                  className="circle bg-gradient"
                  css={css`
                    text-align: center;
                    h2 {
                      font-size: 1.2rem;
                    }
                    p {
                      font-size: 0.8rem;
                    }
                    a {
                      font-size: 0.8rem;
                    }
                    width: calc(100vw - 30px);
                    height: calc(100vw - 30px);
                    padding-top: 2em;
                    padding-bottom: 2em;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media (min-width: 576px) {
                      border-radius: 50%;
                      padding-top: 0em;
                      padding-bottom: 0em;
                      width: calc(100vw - 120px);
                      height: calc(100vw - 120px);
                      h2 {
                        font-size: 1.75rem;
                      }
                      p {
                        font-size: 1rem;
                      }
                      a {
                        font-size: initial;
                      }
                    }
                    @media (min-width: 768px) {
                      border-radius: 50%;
                      width: 360px;
                      height: 360px;
                    }
                    @media (min-width: 992px) {
                      text-align: left;
                      width: 380px;
                      height: 380px;
                    }
                    @media (min-width: 1200px) {
                      width: 400px;
                      height: 400px;
                    }
                  `}
                >
                  <div
                    className="content"
                    css={css`
                      max-width: calc(100vw - 120px);
                      @media (min-width: 576px) {
                        max-width: calc(100vw - 180px);
                      }
                      @media (min-width: 768px) {
                        max-width: 280px;
                      }
                      @media (min-width: 992px) {
                      }
                    `}
                  >
                    <h2
                      className="bold-headline"
                      css={css`
                        font-size: 28px;
                        color: var(--white);
                      `}
                    >
                      {title}
                    </h2>
                    <p
                      className="text-white"
                      css={css`
                        font-size: 16px;
                        line-height: 19px;
                      `}
                    >
                      {subtitle}
                    </p>{" "}
                    <PrimaryLink light to={buttonLink} text={buttonText} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
